// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Breadcrumb                                                               *
\* ------------------------------------------------------------------------ */

.menu-wrapper.breadcrumb {
}

.menu-wrapper.breadcrumb .menu-list {
    font-size: 14px;
    font-size: remify(13.54);
    margin: remify(0 0 40, 16.02);
}

.menu-wrapper.breadcrumb .menu-item {
    &:after {
        content: "/";
        padding: remify(0 4, 16.02);
    }

    &:last-child:after {
        display: none;
    }
}

.menu-wrapper.breadcrumb .menu-item a {
    color: $foreground;
    display: inline;
    padding: 0;

    &:hover {
        color: darken($foreground, 10);
    }
}

.menu-wrapper.breadcrumb .menu-item:last-child a {
    color: lighten(adjust-hue($primary, -0.2625), 38.0392);
    font-weight: 700;

    &:hover {
        color: lighten(lighten(adjust-hue($primary, -0.2625), 38.0392), 10);
    }
}
