// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Content                                                                  *
\* ------------------------------------------------------------------------ */

.contact .address {
    float: left;
    width: (100% / 3);
}

.contact .button {
    clear: both;
}
