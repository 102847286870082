// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Visually Hidden                                                          *
\* ------------------------------------------------------------------------ */

.visually-hidden {
    left: -999em;
    position: absolute;
    top: -999em;
}
