// Styles written by YOURNAME @ YOURCOMPANY

/* ------------------------------------------------------------------------ *\
 * Gridly (adapted from https://github.com/IonicaBizau/gridly)              *
\* ------------------------------------------------------------------------ */

.row {
    & {
        display: flex;
        flex-direction: column;
    }
}

.col {
    & {
        flex: 0 0 auto;
    }
}
