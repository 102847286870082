// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Generic Form Elements                                                    *
\* ------------------------------------------------------------------------ */

input,
label,
.select,
select,
textarea {
    color: darken($foreground, 60);
    font-family: $body-font;
    font-size: 16px;
    font-size: remify(16.02);
    line-height: remify(24, 20);
}

label {
    color: $foreground;
	display: block;
    margin: remify(0 0 20, 20);

    &:hover {
        cursor: pointer;
    }
}

@include placeholder {
	color: darken($foreground, 60);
    opacity: 1;
}

button,
input[type=button],
input[type=reset],
input[type=submit],
.button {
	background: $background;
	border: 0;
    border-radius: remify(4, 20.835);
    color: $foreground;
	display: block;
    font-family: $body-font;
    font-size: 21px;
    font-size: remify(20.835);
    font-weight: 400;
    margin: remify(0 0 20, 16);
    padding: remify(4.5 20, 20.835);
    text-align: center;
    text-transform: uppercase;
	transition: background 0.15s, color 0.15s;

    &:hover {
        background: lighten($background, 5%);
        color: $foreground;
    }

    strong {
        color: $accent;
        font-weight: 700;
    }
}

/*
input[type=checkbox],
input[type=radio] {
	left: -999em;
	position: absolute;

    & + label {
        position: relative;
        padding: remify(11 0 11 52, 16);

        &:before {
            background: $foreground;
            border-radius: remify(5, 24);
            color: darken($foreground, 60);
            content: "\0020";
            display: block;
            float: left;
            font-size: remify(24, 20);
            height: remify(48, 24);
            left: 0;
            line-height: remify(48, 24);
            position: absolute;
            text-align: center;
            top: 0;
            width: remify(48, 24);

            // webkit focus rings to emulate checkbox/radio
            .-webkit- &:active,
            .-webkit- &:focus {
                outline: -webkit-focus-ring-color auto 5px;
            }

            // trident focus rings to emulate checkbox/radio
            .-ms- &:active,
            .-ms- &:focus {
                outline: #808080 dashed 1px;
            }
        }

        &:hover {
            cusror: pointer;
        }
    }
}

input[type=checkbox]:focus + label:before,
input[type=radio]:focus + label:before {
    outline: -webkit-focus-ring-color auto 5px;
}

input[type=checkbox][disabled] + label:before,
input[type=radio][disabled] + label:before {
    background: darken($background, 15%);
}

input[type=checkbox]:checked + label:before {
    @include icon("check");
}

input[type=radio] + label:before {
    border-radius: 50%;
}

input[type=radio]:checked + label:before {
    @include icon("circle");
}
*/

input[type=color],
input[type=date],
input[type=datetime],
input[type=datetime-local],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=range],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
input[type=week],
.select,
textarea {
	background: $foreground;
	box-sizing: border-box;
    border: 0;
    border-radius: remify(5, 20);
    color: darken($foreground, 60);
	display: block;
    line-height: normal;
    margin: remify(0 0 20, 20);
	outline: none;
    padding: remify(12 18 11, 20);
	text-overflow: ellipsis;
	width: 100%;
}

.select {
	padding: 0;
    position: relative;
}

.select:before {
    color: currentColor;
    display: block;
    @include icon("sort");
    font-size: remify(24, 20);
    height: 100%;
    line-height: remify(48, 24);
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: remify(48, 24);
}

.select select {
    background: none;
    border: 0;
    display: block;
    margin: 0;
    outline: none;
    padding: remify(11 48 10 14, 20);
    width: 100%;
    // gecko fix
    text-indent: 0.01px;
    text-overflow: "";
    // webkit & gecko fix
    -webkit-appearance: none;
    -moz-appearance: none;

    // trident fix
    &::-ms-expand {
        display: none;
    }

    &:hover {
        cursor: pointer;
    }
}

textarea {
	max-width: 100%;
    min-height: remify(100, 16);
	min-width: 100%;
}

// fix for inconsistancies accross browsers

.select option {
    background: #FFF;
    color: #000;

    &:hover {
        background: #DDD;
    }
}
