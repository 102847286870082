// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Header                                                                   *
\* ------------------------------------------------------------------------ */

.header-wrapper {
    font-size: 16px;
    font-size: remify(16);
}
