// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Mini Article                                                             *
\* ------------------------------------------------------------------------ */

.mini-article {
    margin: remify(0 0 24, 16);

    &:last-child {
        margin-bottom: 0;
    }
}

.mini-article h1,
.mini-article h2,
.mini-article h3,
.mini-article h4,
.mini-article h5,
.mini-article h6 {
    margin: 0;
}

.mini-article-date {
    color: $foreground;
    flex: none;
    font-size: remify(15.9, 16);
    line-height: remify(24, 15.9);
    text-align: center;
}

.mini-article-date {
    flex: none;
}

.mini-article-date strong {
    color: $foreground;
    display: block;
    flex: 1 1 auto;
    font-size: remify(30, 15.9);
}

.mini-article-title {
    display: inline-block;
    flex: 1 1 auto;
}
