// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Address                                                                  *
\* ------------------------------------------------------------------------ */

.address p {
    strong {
        display: inline;

        & + br {
            display: block;
        }
    }
}
