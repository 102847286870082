// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Content                                                                  *
\* ------------------------------------------------------------------------ */

.contact-wrapper {
    @include layer("content");

    background: $primary;
    font-size: 16px;
    font-size: remify(16);
    padding: 20px;
    padding: remify(20);
}

.contact {
    @extend %clearfix;
    
    margin: 0 auto;
    max-width: $site-width + px;
    max-width: remify($site-width);
    width: auto;
}

.contact .logo {
    margin: remify(-52.1, 16) auto remify(30, 16);
    width: remify(125, 16);
}

.contact .logo img {
    border-radius: remify(2, 16);
}

.contact .address {
    font-size: remify(16, 16);
}

.contact .button {
    font-size: remify(20.835, 16);
}
