// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Address                                                                  *
\* ------------------------------------------------------------------------ */

.address h1,
.address h2,
.address h3,
.address h4,
.address h5,
.address h6 {
    font-size: 18px;
    font-size: remify(18);
}
