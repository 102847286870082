// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Generic Text Elements                                                    *
\* ------------------------------------------------------------------------ */

table {
    display: table;
    overflow: visible;
    padding-bottom: 0;
}
