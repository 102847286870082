// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Mobile Navigation                                                        *
\* ------------------------------------------------------------------------ */

// wrapper

.mobile-nav-wrapper {
    bottom: 0;
    font-size: 16px;
    font-size: remify(16);
    overflow: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: remify($mobile-nav-width, 16);
    z-index: 1;
}

.mobile-nav .search-form {
    margin: remify(16 20, 16);
}

.mobile-nav .menu-list {
    font-size: remify(16, 16);
    font-weight: 700;
    text-transform: uppercase;
}

.mobile-nav .menu-list.alt {
    font-size: remify(14, 16);
    font-weight: 400;
    text-transform: none;
}

.mobile-nav .menu-item a {
    color: $foreground;
    padding: remify(10 20, 16);
}

.mobile-nav .menu-list.alt .menu-item a {
    color: $accent;
    padding: remify(10 20, 14);
}

.mobile-nav .menu-toggle {
    color: $foreground;
    font-size: remify(16, 16);
    height: remify(40, 16);
    width: remify(40, 16);

    &:hover {
        color: $foreground;
    }

    &:before,
    &:after {
        top: remify(18, 16);
    }
}

.mobile-nav .menu-list.alt .menu-toggle {
    color: $accent;

    &:hover {
        color: $accent;
    }
}

.mobile-nav .menu-list.sub-menu {
    background: $primary;

    .menu-list.sub-menu {
        background: lighten($primary, 10);
    }

    .menu-item:first-child {
        padding-top: remify(10, 16);
    }

    .menu-item:last-child {
        padding-bottom: remify(10, 16);
    }
}
