// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Address                                                                  *
\* ------------------------------------------------------------------------ */

.address {
}

.address h1,
.address h2,
.address h3,
.address h4,
.address h5,
.address h6 {
    color: $foreground;
    font-family: $heading-font;
    font-size: 14px;
    font-size: remify(13.54);
    font-weight: 400;
    line-height: remify(18.63, 13.54);
    margin: remify(0 0 30, 16);
    text-align: center;
    text-transform: uppercase;

    strong {
        color: $accent;
        display: block;
        font-size: remify(20.835, 13.54);
        font-weight: 700;
        
        a {
            color: $accent;
        }
    }
}

.address p {
    strong {
        display: block;
        font-weight: 700;
        text-transform: uppercase;

        & + br {
            display: none;
        }
    }
}
