// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Generic Text Elements                                                    *
\* ------------------------------------------------------------------------ */

::selection {
	background: $primary;
	color: #FFF;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $foreground;
    font-family: $heading-font;
    font-weight: 400;
    text-transform: uppercase;

    strong {
        color: $accent;
    }
}

h1 {
    font-size: 21px;
    font-size: remify(20.835);
    margin: remify(0 0 14, 20.835);
}

h2,
h3 {
    color: $accent;
    font-size: 14px;
    font-size: remify(13.54);
    font-weight: 700;
    margin: remify(0 0 22, 13.54);
}

h3 {
    margin: 0;
}

h4 {
    font-size: 22px;
    font-size: remify(22);
    margin: remify(0 0 16, 22);
}

h5,
h6 {
    font-size: 14px;
    font-size: remify(13.54);
    font-weight: 700;
    margin: remify(0 0 24, 13.54);
}

menu,
ol,
p,
table,
ul {
    color: $foreground;
    font-family: $body-font;
    font-size: 14px;
    font-size: remify(13.54);
    line-height: remify(20.835, 13.54);
    margin: remify(0 0 22, 13.54);
}

menu,
ol,
ul {
    padding: remify(0 0 0 24, 16);

    menu,
    ol,
    ul {
		margin-bottom: 0;
    }
}

table {
    color: darken($foreground, 60);
    border-collapse: separate;
	border-spacing: 0;
    display: block;
    overflow: auto;
    padding-bottom: 1px;
    padding-bottom: remify(1, 16);
    width: 100%;
    // iOS fix
    -webkit-overflow-scrolling: touch;
}

table th,
table td,
table tf {
    background: $foreground;
    border: 1px solid darken($foreground, 13);
    border-left-width: 0;
    border-bottom-width: 0;
    padding: remify(12 20, 16.02);
    vertical-align: top;

    &:first-child {
        border-left-width: 1px;
    }
}

table tr:last-child th,
table tr:last-child td,
table tr:last-child tf {
    border-bottom-width: 1px;
}

table th {
    font-weight: 700;
    text-align: left;
}

table tf {
    font-style: italic;
}

table thead th,
table thead td,
table thead tf {
    background: $background;
    border-color: $background;
    color: $foreground;
    font-weight: 700;
    text-transform: uppercase;
}

table thead:nth-last-child(n+2) tr:last-child th,
table thead:nth-last-child(n+2) tr:last-child td,
table thead:nth-last-child(n+2) tr:last-child tf {
    border-bottom: 0;
}

table tfoot th,
table tfoot td,
table tfoot tf {
    background: $background;
    border-color: $background;
    color: $foreground;
    font-style: italic;
}

table tfoot:nth-child(n+2) tr:first-child th,
table tfoot:nth-child(n+2) tr:first-child td,
table tfoot:nth-child(n+2) tr:first-child tf {
    border-top: 0;
}

table thead:first-child tr:first-child th:first-child,
table thead:first-child tr:first-child td:first-child,
table thead:first-child tr:first-child tf:first-child,
table tbody:first-child tr:first-child th:first-child,
table tbody:first-child tr:first-child td:first-child,
table tbody:first-child tr:first-child tf:first-child,
table tfoot:first-child tr:first-child th:first-child,
table tfoot:first-child tr:first-child td:first-child,
table tfoot:first-child tr:first-child tf:first-child,
table > tr:first-child th:first-child,
table > tr:first-child td:first-child,
table > tr:first-child tf:first-child {
    border-top-left-radius: remify(5, 16.02);
}

table thead:first-child tr:first-child th:last-child,
table thead:first-child tr:first-child td:last-child,
table thead:first-child tr:first-child tf:last-child,
table tbody:first-child tr:first-child th:last-child,
table tbody:first-child tr:first-child td:last-child,
table tbody:first-child tr:first-child tf:last-child,
table tfoot:first-child tr:first-child th:last-child,
table tfoot:first-child tr:first-child td:last-child,
table tfoot:first-child tr:first-child tf:last-child,
table > tr:first-child th:last-child,
table > tr:first-child td:last-child,
table > tr:first-child tf:last-child {
    border-top-right-radius: remify(5, 16.02);
}

table thead:last-child tr:last-child th:first-child,
table thead:last-child tr:last-child td:first-child,
table thead:last-child tr:last-child tf:first-child,
table tbody:last-child tr:last-child th:first-child,
table tbody:last-child tr:last-child td:first-child,
table tbody:last-child tr:last-child tf:first-child,
table tfoot:last-child tr:last-child th:first-child,
table tfoot:last-child tr:last-child td:first-child,
table tfoot:last-child tr:last-child tf:first-child,
table > tr:first-child th:last-child,
table > tr:first-child td:last-child,
table > tr:first-child tf:last-child {
    border-bottom-left-radius: remify(5, 16.02);
}

table thead:last-child tr:last-child th:last-child,
table thead:last-child tr:last-child td:last-child,
table thead:last-child tr:last-child tf:last-child,
table tbody:last-child tr:last-child th:last-child,
table tbody:last-child tr:last-child td:last-child,
table tbody:last-child tr:last-child tf:last-child,
table tfoot:last-child tr:last-child th:last-child,
table tfoot:last-child tr:last-child td:last-child,
table tfoot:last-child tr:last-child tf:last-child,
table > tr:last-child th:last-child,
table > tr:last-child td:last-child,
table > tr:last-child tf:last-child {
    border-bottom-right-radius: remify(5, 16.02);
}

a {
    color: lighten(adjust-hue($primary, -0.2625), 38.0392);
    text-decoration: none;
    transition: color 0.15s;

    &:hover {
        color: lighten(lighten(adjust-hue($primary, -0.2625), 38.0392), 10);
    }
}

pre {
    background: $primary;
    border: 1px solid darken($primary, 15%);
    display: block;
    color: #FFF;
    font-size: 16px;
    font-size: remify(16);
    padding: remify(20 24, 16);
    width: 100%;
}
