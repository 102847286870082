// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * PhotoSwipe (http://photoswipe.com/)
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern */

// Vendor

@import "../../vendor/_photoswipe";
@import "../../vendor/_photoswipe-default-skin";

// Custom

.pswp__button,
.pswp__button--arrow--left::before,
.pswp__button--arrow--right::before {
    & {
        background-image: url("../media/photoswipe-default-skin.png");
    }
}

@media (-webkit-min-device-pixel-ratio: 1.1), (-webkit-min-device-pixel-ratio: 1.09375), (min-resolution: 105dpi), (min-resolution: 1.1dppx) {
    .pswp--svg .pswp__button,
    .pswp--svg .pswp__button--arrow--left::before,
    .pswp--svg .pswp__button--arrow--right::before {
        background-image: url("../media/photoswipe-default-skin.svg");
    }
}

.pswp__preloader--active .pswp__preloader__icn {
    & {
        background-image: url("../media/photoswipe-preloader.gif");
    }
}
