// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Header                                                                   *
\* ------------------------------------------------------------------------ */

.header-wrapper {
    @include layer("header");

    background: $background;
    box-shadow: remify(0 4 0 0, 16) transparentize(invert($foreground), 0.8);
    font-size: 16px;
    font-size: remify(16);
    padding: 20px;
    padding: remify(20);
}

.header {
    @extend %clearfix;

    margin: 0 auto;
    max-width: $site-width + px;
    max-width: remify($site-width);
    position: relative;
}

.header .logo {
    left: 0;
    position: absolute;
    top: 0;
    width: remify(125, 16);
}

.header .logo img {
    border-radius: remify(4, 16);
    box-shadow: remify(0 4 0 0, 16) transparentize(invert($foreground), 0.8);
}

.header .menu-button {
    float: right;
}

.header-right {
    @extend %clearfix;

    float: right;
    width: remify(680, 16);
}

.header-right-group {
    align-content: center;
    align-items: center;
    display: flex;
    padding: remify(0 32, 16);
}

.header .menu-wrapper {
    flex: none;
    float: right;

    .flexbox &,
    .flexboxlegacy & {
        float: none;
    }

    .no-flexbox.no-flexboxlegacy.no-flexboxtweener & {
        float: left;
    }
}

.header .menu-list {
    font-size: remify(21, 16);
    font-weight: 700;
    text-transform: uppercase;

    &.alt {
        font-size: remify(15, 16);
        font-weight: 400;
        text-transform: none;
    }
}

.header .menu-item a {
    color: $foreground;
    padding: remify(18 32, 21);

    &:hover {
        color: darken($foreground, 10);
    }
}

.header .menu-list.alt .menu-item a {
    color: $accent;

    &:hover {
        color: lighten($accent, 10);
    }
}

.header .menu-list.sub-menu {
    background: $primary;
    font-size: remify(16, 21);

    .menu-list.sub-menu {
        background: lighten($primary, 10);
        font-size: remify(16, 16);
    }
}

.header .search-form {
    float: left;
    flex: 1 1 auto;

    .flexbox &,
    .flexboxlegacy & {
        float: none;
    }
}
