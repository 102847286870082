// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Footer                                                                   *
\* ------------------------------------------------------------------------ */

.footer p {
    font-size: remify(15);
}

.footer .address {
    text-align: left;
    width: 20%;

    &:nth-of-type(2n+3) {
        clear: none;
        width: 30%;
    }
}

.sitemap {
    font-size: remify(15, 16);
    text-align: left;
    width: 30%;
}

.sitemap .menu-list {
    text-align: left;
}
