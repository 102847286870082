// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Content                                                                  *
\* ------------------------------------------------------------------------ */

.contact-wrapper {
    font-size: 12px;
    font-size: remify(12);
}

.contact {
    align-content: center;
    align-items: center;
    display: flex;

    .no-flexbox.no-flexboxlegacy.no-flexboxtweener & {
        display: table;
        vertical-align: middle;
    }
}

.contact .logo {
    @include flex-fix;

    flex: none;
    float: left;
    margin: remify(0 20 0 0, 16);
    width: remify(105, 16);

    .no-flexbox.no-flexboxlegacy.no-flexboxtweener & {
        display: table-cell;
        float: none;
    }
}

.contact .address {
    @include flex-fix;

    flex: 1 1 auto;
    float: left;
    padding: remify(0 20, 16);

    .no-flexbox.no-flexboxlegacy.no-flexboxtweener & {
        display: table-cell;
        float: none;
        width: auto;
    }
}

.contact .address h1,
.contact .address h2,
.contact .address h3,
.contact .address h4,
.contact .address h5,
.contact .address h6 {
    font-size: remify(18, 16);
    margin: 0;
    white-space: nowrap;
}

.contact .button {
    @include flex-fix;

    flex: none;
    float: left;
    margin: remify(0 0 0 20, 20.835);

    .no-flexbox.no-flexboxlegacy.no-flexboxtweener & {
        display: table-cell;
        float: none;
        width: auto;
    }
}
