// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Widget                                                                   *
\* ------------------------------------------------------------------------ */

.widget-title h1,
.widget-title h2,
.widget-title h3,
.widget-title h4,
.widget-title h5,
.widget-title h6 {
    font-size: remify(30.03, 16);
}
