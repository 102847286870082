// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Footer                                                                   *
\* ------------------------------------------------------------------------ */

.footer .address {
    float: left;
    width: 50%;

    &:nth-of-type(2n+3) {
        clear: left;
    }
}

.sitemap {
    float: left;
    font-size: remify(14, 16);
    text-align: center;
    width: 50%;
}

.sitemap .menu-list {
    line-height: remify(24, 14);
}

.sitemap .menu-item {
    display: inline;
    float: none;
}

.sitemap .menu-item:after {
    content: "|";
    padding: remify(0 6, 14);
}

.sitemap .menu-item:last-child:after {
    display: none;
}

.sitemap .menu-item a {
    display: inline;
    padding: 0;
}

.sitemap p {
    color: lighten(desaturate(adjust-hue($background, -0.5116), 79.1667), 19.8039);
}
