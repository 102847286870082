// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * wp-caption                                                               *
\* ------------------------------------------------------------------------ */

.wp-caption {
    background: $secondary;
    margin: remify(0 0 10, 16);
    padding: remify(6, 16);
}

.wp-caption img {
    display: block;
    width: 100%;
}

.wp-caption-text {
    color: $background;
    margin: 0;
    padding: remify(6 20 3, 20);
}

.wp-caption-text a {
    color: $background;
    display: block;
}
