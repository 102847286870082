// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Generic Text Elements                                                    *
\* ------------------------------------------------------------------------ */

h1 {
    font-size: 30px;
    font-size: remify(30.03);
}

h2,
h3 {
    font-size: 20px;
    font-size: remify(20);
}

h5,
h6 {
    font-size: 16px;
    font-size: remify(16);
}

menu,
ol,
p,
table,
ul {
    font-size: 16px;
    font-size: remify(16.02);
    line-height: remify(24, 16.02);
}
