// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Hiders                                                                   *
\* ------------------------------------------------------------------------ */

.hide-xxxxl, .hide-xxxl, .hide-xxl, .hide-xl, .hide-l, .hide-m, .hide-s, .hide-xs, .hide-xxs {
    display: block !important;
}

.show-xxxxl, .show-xxxl, .show-xxl, .show-xl, .show-l, .show-m, .show-s, .show-xs, .show-xxs {
    display: none !important;
}
