// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Content                                                                  *
\* ------------------------------------------------------------------------ */

.contact .address {
    padding: remify(0 20, 16);
}

.contact .address h1,
.contact .address h2,
.contact .address h3,
.contact .address h4,
.contact .address h5,
.contact .address h6 {
    align-content: center;
    align-items: center;
    display: flex;
}

.contact .address strong {
    margin: remify(0 0 0 10, 20.835);
}
