// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Content                                                                  *
\* ------------------------------------------------------------------------ */

.content-wrapper {
    padding-bottom: 40px;
    padding-bottom: remify(40);
    padding-top: 50px;
    padding-top: remify(50);

    .slideshow-wrapper & {
        padding-top: 40px;
        padding-top: remify(40);
    }
}

.content {
    padding: remify(0 30 0 0, 16);

    .home & {
        padding: 0;
    }
}

.post {
    float: left;
    padding: remify(0 50 0 0, 16);
    width: (1000 / $site-width) * 100%;

    .home & {
        padding: remify(0 80 0 30, 16);
        width: (890 / $site-width) * 100%;
    }

    &:only-child {
        float: none;
        padding: remify(0 30, 16) !important;
        width: auto !important;
    }
}

.sidebar {
    float: right;

    width: (370 / $site-width) * 100%;

    .home & {
        width: (510 / $site-width) * 100%;
    }
}
