// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Widget                                                                   *
\* ------------------------------------------------------------------------ */

.widget {
    background: $background;
    border-radius: remify(6, 16);
    margin: remify(0 0 20, 16);
    padding: remify(15 20, 16);

    &:last-child {
        margin-bottom: 0;
    }
}

.widget-title h1,
.widget-title h2,
.widget-title h3,
.widget-title h4,
.widget-title h5,
.widget-title h6 {
    font-size: remify(20.835, 16);
    font-weight: 400;
    margin: remify(0 0 10, 20.835);
}
