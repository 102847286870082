// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Breadcrumb                                                               *
\* ------------------------------------------------------------------------ */

.menu-wrapper.breadcrumb .menu-list {
    font-size: 16px;
    font-size: remify(16.02);
}
