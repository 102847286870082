// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Slideshow                                                                *
\* ------------------------------------------------------------------------ */

.slideshow {
    box-shadow: remify(0 4 0, 16) transparentize(invert($foreground), 0.8);
}

.slideshow-captions {

    display: flex;
}

.slideshow-caption {
    @include flex-fix;

    background: $background;
    display: flex;
    flex-direction: column;
    float: left;
    transition: background 0.15s;
    width: (100% / 4);

    &.active {
        background: $foreground;
    }
}

.slideshow-caption-title {
    background: none !important;
    border-bottom: 0;
    border-top: remify(5, 16) solid $background;
    flex: none;
    position: relative;

    .active & {
        border-top-color: $accent;
    }

    &:before {
        border-bottom: remify(12.81923076923078, 16) solid $background;
        border-left: remify(12.81923076923078, 16) solid transparent;
        border-right: remify(12.81923076923078, 16) solid transparent;
        content: "\0020";
        display: block;
        height: 0;
        left: remify(10, 16);
        opacity: 0;
        position: absolute;
        transition: border-bottom-color 0.15s, opacity 0.15s;
        top: remify(-16.81923076923078, 16);
        width: 0;
    }

    .active &:before {
        border-bottom-color: $accent;
        opacity: 1;
    }
}

.slideshow-caption-title h6 {
    padding-top: remify(16, 16.665);
}

.slideshow-caption-content {
    background: none;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    max-height: none;
}

.slideshow-caption-content p {
    color: $secondary;
    color: transparentize($foreground, 0.6);
    flex: 1 1 auto;
    padding-bottom: remify(20, 10.25538461538462);
    transition: color 0.15s;

    .active & {
        color: $primary;
    }
}

.slideshow-caption-content .button {
    background: url("../media/background-white-10.png");
    background: transparentize($foreground, 0.9);
    color: $secondary;
    color: transparentize($foreground, 0.6);

    .active & {
        background: $accent;
        color: $primary;
    }
}
