// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Menu Buton                                                               *
\* ------------------------------------------------------------------------ */

.is-navopen .menu-button:before,
.is-navopen .menu-button:after {
    transition: box-shadow 0.15s, top 0.15s, transform 0.15s 0.15s;
}

.is-navopen .menu-button:before {
    box-shadow: 0 0 0 0 transparent;
    transform: rotate(45deg);
    top: 0;
}

.is-navopen .menu-button:after {
    transform: rotate(-45deg);
    top: 0;
}
