// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Mini Product                                                             *
\* ------------------------------------------------------------------------ */

.mini-product {
    @extend %clearfix;

    align-content: center;
    align-items: center;
    display: flex;

    &:not(:last-child) {
        margin-bottom: remify(10, 16);
    }

    &.-grid {
        margin-bottom: remify(24, 16);
    }
}

.mini-product-image {
    float: left;
    margin: 0;
    width: 45%;

    .flexbox &,
    .flexboxlegacy & {
        float: none;
    }
}

.mini-product-image img {
    display: block;
    height: auto !important;
    width: 100%;
}

.mini-product-header {
    float: left;
    padding: remify(0 0 0 16, 16);
    width: 55%;

    .flexbox &,
    .flexboxlegacy & {
        float: none;
    }
}

.mini-product-heading,
.mini-product-price {
    font-family: $heading-font;
    font-size: remify(13.54, 16);
    font-weight: 700;
    line-height: remify(15.205, 13.54);
    text-transform: uppercase;
}

.mini-product-heading {
    color: lighten(adjust-hue($primary, -0.2625), 38.0392);
    font-size: remify(20, 16);
    margin-bottom: remify(4, 20);
}

.mini-product-heading a {
    color: lighten(adjust-hue($primary, -0.2625), 38.0392);

    &:hover {
        color: lighten(lighten(adjust-hue($primary, -0.2625), 38.0392), 10);
    }
}

.mini-product-price {
    color: $foreground;
    margin: 0;
}

.mini-product-price a {
    color: $foreground;

    &:hover {
        color: $accent;
    }
}
