// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Header                                                                   *
\* ------------------------------------------------------------------------ */

.header-wrapper {
    font-size: 12px;
    font-size: remify(12);
    padding-bottom: 12px;
    padding-bottom: remify(12);
    padding-top: 28px;
    padding-top: remify(28);
}

.header .logo {
    width: remify(255, 16);
}
