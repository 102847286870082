// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Align                                                                    *
\* ------------------------------------------------------------------------ */

.alignleft {
    float: left;
    margin-right: remify(60, 16);
}

.alignright {
    float: right;
    margin-left: remify(60, 16);
}
