// Styles written by YOURNAME @ YOURCOMPANY

/* ------------------------------------------------------------------------ *\
 * Helpers                                                                  *
\* ------------------------------------------------------------------------ */

// imports

@import "_variables";
@import "_functions";
@import "_mixins";

// generic helpers

%clearfix {
    *zoom: 1 !important;

    &:before,
    &:after {
        content: "\0020" !important;
        display: table !important;
    }

    &:after {
        clear: both !important;
    }
}

._center {
    margin-left: auto !important;
    margin-right: auto !important;
}

._left {
    float: left !important;
}

._right {
    float: right !important;
}

._textcenter {
    text-align: center !important;
}

._textleft {
    text-align: left !important;
}

._textright {
    text-align: right !important;
}

._nomargin {
    margin: 0 !important;
}

._nopadding {
    padding: 0 !important;
}

._visuallyhidden {
    left: -999em !important;
    position: absolute !important;
    top: -999em !important;
}

/* visibility classes */
// ideal: https://www.w3.org/TR/css-display-3/#propdef-box-suppress

@media screen and (min-width: $s-break) {
    ._mobile {
        display: none !important;
    }
}

@media screen and (max-width: ($s-break - 1 / 16)) and (min-width: $xl-break) {
    ._tablet {
        display: none !important;
    }
}

@media screen and (max-width: ($s-break - 1 / 16)) {
    ._tablet._desktop {
        display: none !important;
    }
}

@media screen and (max-width: ($xl-break - 1 / 16)) {
    ._desktop:not(._tablet) {
        display: none !important;
    }
}
