// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Meta                                                                     *
\* ------------------------------------------------------------------------ */

.meta-list {
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: left;
}

.meta-list.l-center {
    float: right;
    position: relative;
    right: 50%;
}

.meta-list li {
    display: inline-block;
    padding: 0 10px 0 20px;
    position: relative;
}

.meta-list.l-center li {
    float: left;
    left: 50%;
    position: relative;
}

.meta-list li:before {
    left: 0;
    position: absolute;
    top: 1px;
}

.meta-list li.comments:before {
    @include icon("comment");
}

.meta-list li.categories:before {
    @include icon("folder");
}

.meta-list li.tags:before {
    @include icon("tag");
}

.meta-list li.time:before {
    @include icon("clock-o");
}

.meta-list li.url:before {
    @include icon("globe");
}
