// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Page                                                                     *
\* ------------------------------------------------------------------------ */

.page-wrapper {
    left: auto;
    min-height: 0;
    position: static;
    transition: none;
    width: auto;
    z-index: auto;
}
