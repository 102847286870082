// Styles written by YOURNAME @ YOURCOMPANY

/* ------------------------------------------------------------------------ *\
 * Mixins                                                                   *
\* ------------------------------------------------------------------------ */

// flex fix for safari

@mixin flex-fix {
    .flexbox & {
        float: none;
    }
}

// placeholders

@mixin placeholder {
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder {@content}
    ::-moz-placeholder {@content}
    :-ms-input-placeholder {@content}
    .placeholder {@content}
}

// quantity queries

@mixin quantity-query($number, $comparison: "equal") {
    $nth: "";

    @if index(("greater" "more" ">"), $comparison) {
        $nth: "n + #{$number}";
    } @else if index( ('less' 'fewer' '<'), $comparison) {
        $nth: "-n + #{$number}";
    } @else if index(("equal" "same" "="), $comparison) {
        $nth: $number;
    } @else {
        @warn "Sorry, that's an invalid $comparison value."
    }

    &:nth-last-child(#{$nth}):first-child {
        &, & ~ * {
            @content;
        }
    }
}

@mixin qq-equal($number) {
  @include quantity-query($number, equal) {
      @content;
  }
}

@mixin qq-greater($number) {
    @include quantity-query($number, greater) {
        @content;
    }
}

@mixin qq-less($number) {
    @include quantity-query($number, less) {
        @content;
    }
}

// layers

@mixin layer($layer-name) {
    position: relative;
    z-index: map-get($layers, $layer-name);
}

// icons

@mixin icon($icon: null) {
    content: map-get($fa-icons, $icon);
    font-family: "fontawesome";
}
