// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * WooCommerce                                                              *
\* ------------------------------------------------------------------------ */

.woocommerce-breadcrumb {
    & {
        color: $foreground;
        font-family: $body-font;
        font-size: remify(16, 16);
        margin-bottom: remify(32, 16);
    }

    > a {
        color: $foreground;
    }

    > a:hover {
        color: $foreground_alt;
    }

    > .last {
        color: lighten(saturate(adjust-hue($primary, -0.2625), 0.0000), 38.0392);
        font-weight: 700;
    }
}

.woocommerce-message {
    color: $foreground;
    font-family: $body-font;
    font-size: 14px;
    font-size: remify(13.54);
    line-height: remify(20.835, 13.54);
    margin: remify(0 0 22, 13.54);
}

.woocommerce-message .button {
    display: inline-block;
    margin: remify(0 20 0 0, 16);
}

.woocommerce-pagination {
    & {
    }

    > .page-numbers {
        list-style: none;
        margin: 0;
        overflow: hidden;
        padding: 0;
        text-align: center;
    }

    > .page-numbers > li {
        display: inline-block;
    }

    > .page-numbers > li > .page-numbers {
        display: inline-block;
        padding: remify(8 12, 16);
    }

    > .page-numbers > li > .page-numbers.current {
    }

    > .page-numbers > li > .page-numbers.prev {
    }

    > .page-numbers > li > .page-numbers.next {
    }
}

.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button {
    & {
        @extend .button;
        background: $background !important;
        color: $foreground !important;
    }

    &:hover {
        background: lighten($background, 5%) !important;
    }
}

.woocommerce .select2-results {
    & {
        color: #000;
    }
}

.woocommerce .wc_payment_method label {
    & {
        color: #000;
        padding-left: 0;
    }

    &:before {
        display: none;
    }
}

.woocommerce .payment_box {
    ol,
    p,
    table,
    ul {
        color: #000;
    }
}

.woocommerce ul#shipping_method li label, .woocommerce table.shop_table tr.shipping p {
	color: darken($foreground, 60);
}

.woocommerce .quantity .qty {
    border: 1px solid darken($foreground, 13);
}

.woocommerce .shop_table.cart .product-name .variation dt {
    float: none;
}

.woocommerce .product-price del {
    display: none;
}

@media screen and (max-width: $s-break) {
    .woocommerce .woocommerce-cart-form .shop_table_responsive tr:nth-child(2n) td,
    .woocommerce .cart_totals .shop_table_responsive tr:nth-child(2n) td {
        background: $light_alt;
    }
}
