// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Page                                                                     *
\* ------------------------------------------------------------------------ */

.page-wrapper {
    background: url("../media/background-content-wrapper.png") left bottom repeat-x $primary;
    font-size: 16px;
    font-size: remify(16);
    left: 0;
    min-height: 100%;
    position: relative;
    transition: box-shdow 0.15s, left 0.15s;
    width: 100%;
    z-index: 2;
}
