// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Hiders                                                                   *
\* ------------------------------------------------------------------------ */

.hide-xxs {
    display: none !important;
}

.show-xxs {
    display: block !important;
}
