// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Mini Product                                                             *
\* ------------------------------------------------------------------------ */

.mini-product {
    &.-grid {
        padding-right: remify(24, 16);
    }
}
