// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Product                                                                  *
\* ------------------------------------------------------------------------ */

.product-sidebar {
    float: left;
    width: (100% / 3);
}

.product-content {
    float: left;
    padding-left: 50px;
    padding-left: remify(50);
    width: (100% / 3) * 2;
}

.product-cart-form {
    flex-wrap: nowrap;
}

.product-cart-form label {
    padding-right: remify(30, 20);
}

.product-cart-form button {
    flex: none;
    font-size: remify(20, 16);
    width: auto;
}
