// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Footer                                                                   *
\* ------------------------------------------------------------------------ */

.footer-wrapper {
    @include layer("footer");

    background: $background;
    font-size: 16px;
    font-size: remify(16);
    padding: 36px 20px;
    padding: remify(36 20);
}

.footer {
    @extend %clearfix;

    margin: 0 auto;
    max-width: $site-width + px;
    max-width: remify($site-width);
}

.footer a {
    color: $foreground;

    &:hover {
        color: $accent;
    }
}

.footer .address {
    text-align: center;

    &:last-of-type p:last-child {
        margin-bottom: 0;
    }
}

 .footer address strong:not(:first-child) {
    margin-top: remify(22, 13.54);
}
