// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Slideshow                                                                *
\* ------------------------------------------------------------------------ */

.slideshow-wrapper {
    @include layer("slideshow");

    font-size: 16px;
    font-size: remify(16);
    padding: 35px 20px;
    padding: remify(35 20);
}

.slideshow {
    border-radius: remify(7, 16);
    margin: 0 auto;
    max-width: $site-width + px;
    max-width: remify($site-width);
    overflow: hidden;
}

.slideshow-slides {
    background: $foreground;
    height: 0;
    padding-bottom: (540 / 1400) * 100%;
    position: relative;
    width: 100%;
    z-index: 1;
}

.slideshow-slide {
    bottom: 0;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    transition: opacity 0.15s;
    top: 0;
    z-index: 1;

    &.active {
        opacity: 1;
        z-index: 2;
    }
}

.slideshow-slide img {
    display: block;
    width: 100%;
}

.slideshow-captions {
    @extend %clearfix;
    position: relative;
    z-index: 2;
}

.slideshow-caption {
    background: $foreground;

    &:last-child {
        border-bottom: 0;
    }
}

.slideshow-caption-title {
    background: $background;
    border-bottom: 1px solid $secondary;
    border-top: 0 solid $accent;
    position: relative;
    transition: background 0.15s, border 0.15s;

    .active & {
        background: $foreground;
        border-bottom-color: $foreground;
        border-top-width: remify(5, 16);
    }

    .slideshow-caption:last-child & {
        border-bottom: 0;
    }
}

.slideshow-caption-title h6 {
    color: $secondary;
    color: transparentize($foreground, 0.6);
    cursor: pointer;
    font-family: $heading-font;
    font-size: remify(16.665, 16);
    font-weight: 700;
    line-height: normal;
    margin: 0;
    padding: remify(11 15 10, 16.665);
    text-transform: uppercase;
    transition: color 0.15s;

    .active & {
        color: $primary;
    }
}

.slideshow-caption-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s;

    .active & {
        max-height: remify(500, 16);
        transition: max-height 0.5s;
    }
}

.slideshow-caption-content p {
    color: $primary;
    font-family: $body-font;
    font-size: remify(10.25538461538462, 16);
    font-weight: 400;
    line-height: remify(15.38307692307693, 10.25538461538462);
    margin: 0;
    padding: remify(0 15 15, 10.25538461538462);
}

.slideshow-caption-content .button {
    background: $accent;
    border-radius: 0;
    color: $primary;
    display: block;
    font-family: $heading-font;
    font-size: remify(10.25538461538462, 16);
    font-weight: 700;
    margin: 0;
    padding: remify(8 15, 10.25538461538462);
    text-transform: uppercase;
}
