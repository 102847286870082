// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Page                                                                     *
\* ------------------------------------------------------------------------ */

html,
body {
    background: $background;
    height: 100%;
    overflow-x: hidden;
    width: 100%;
    // iOS fix
    -webkit-overflow-scrolling: touch;
}

// IE snap fix

@-ms-viewport {
    width: device-width;
}
