// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Content                                                                  *
\* ------------------------------------------------------------------------ */

.content-wrapper {
    @include layer("content");

    font-size: 16px;
    font-size: remify(16);
    padding: 40px 20px 70px;
    padding: remify(40px 20 70);

    .slideshow-wrapper + & {
        padding-top: 0;
    }
}

.content {
    @extend %clearfix;
	
    margin: 0 auto;
    max-width: $site-width + px;
    max-width: remify($site-width);
    width: auto;
}

.post {
}

.post header h1:nth-last-child(n+1) {
    margin: 0;
}

.sidebar {
}
