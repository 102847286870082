// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Mini Article                                                             *
\* ------------------------------------------------------------------------ */

.mini-article h1 a,
.mini-article h2 a,
.mini-article h3 a,
.mini-article h4 a,
.mini-article h5 a,
.mini-article h6 a {
    display: flex;

    .no-flexbox.no-flexboxlegacy.no-flexboxtweener & {
        display: table;
    }
}

.mini-article-date {
    margin: remify(0 40 0 0, 15.9);

    .no-flexbox.no-flexboxlegacy.no-flexboxtweener & {
        display: table-cell;
    }
}

.mini-article-title {
    .no-flexbox.no-flexboxlegacy.no-flexboxtweener & {
        display: table-cell;
    }
}
