// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Product                                                                  *
\* ------------------------------------------------------------------------ */

figure.product-image {
    margin: 0 0 20px;
    margin: remify(0 0 20);
}

figure.product-image img {
    display: block;
    height: auto !important;
    width: 100%;
}

table.product-details {
    color: $foreground;
    display: table;
    overflow: visible;
}

table.product-details th,
table.product-details td,
table.product-details tf {
    background: none;
    border: 0;
    border-radius: 0;
    padding: remify(2 0, 16.02);
}

table.product-details th {
    color: lighten(adjust-hue($primary, -0.2625), 38.0392);
    text-transform: uppercase;
}

table.product-details td:last-child {
    text-align: right;
}

.product {
    @extend %clearfix;
}

.product-cart-form {
    align-content: center;
    align-items: center;
    display: flex;
    margin: remify(0 0 20, 20);

    .no-flexbox.no-flexboxlegacy & {
        display: table;
    }
}

.product-cart-form > input[type=number],
.product-cart-form > input[type=text] {
    flex: 1 1 auto;
    margin: 0;
    max-width: remify(100, 20);
    min-width: remify(48, 20);
    text-align: center;
    width: 0;

    .no-flexbox.no-flexboxlegacy & {
        display: table-cell;
        width: remify(250, 20);
    }
}

.product-cart-form div.quantity {
    flex: 1 1 auto;
    max-width: remify(100, 16);
    min-width: remify(48, 16);
    width: 0;

    .no-flexbox.no-flexboxlegacy & {
        display: table-cell;
        width: remify(250, 20);
    }
}

.product-cart-form div.quantity > input[type=number],
.product-cart-form div.quantity > input[type=text] {
    margin: 0;
    width: 100%;
}

.product-cart-form label {
    flex: none;
    margin: 0;
    padding: remify(0 20 0 20, 20);
    text-transform: uppercase;

    .no-flexbox.no-flexboxlegacy & {
        display: table-cell;
    }
}

.product-cart-form button {
    flex:  none;
    font-size: remify(18, 16);
    margin: 0;
    white-space: nowrap;
    width: auto;

    .no-flexbox.no-flexboxlegacy & {
        display: table-cell;
        width: auto;
    }
}
