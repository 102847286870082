// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Align                                                                    *
\* ------------------------------------------------------------------------ */

.aligncenter,
.alignleft,
.alignright {
    display: block;
    margin: 0 auto remify(60, 16);
}
