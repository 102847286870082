// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Page                                                                     *
\* ------------------------------------------------------------------------ */

.is-navopen body {
    overflow: visible;
}

.is-navopen .page-wrapper {
    box-shadow: none;
    left: auto;
    width: auto;
}
