// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Logo                                                                     *
\* ------------------------------------------------------------------------ */

.logo {
    display: block;
}

.logo img {
    display: block;
    width: 100%;
}
