// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Search Form                                                              *
\* ------------------------------------------------------------------------ */

.search-form {
    align-items: center;
    background: $foreground;
    border-radius: remify(4, 16);
	display: flex;
}

.search-form {
    @include placeholder {
        color: $primary;
        font-weight: 700;
        opacity: 1;
        text-transform: uppercase;
    }
}

label.search-label {
    flex: none;
    margin: 0;
}

input.search-input {
    background: none;
    border: 0;
    color: $primary;
    flex: 1 1 auto;
    font-size: remify(15, 16);
    margin: 0;
    padding: remify(8 0 7 16, 15);
    width: 0;

    .no-flexbox.no-flexboxlegacy.no-flexboxtweener & {
        float: left;
        width: remify(225, 15);
    }
}

button.search-submit,
input.search-submit {
    background: none;
    flex: none;
    font-size: remify(15, 16);
    font-weight: 400;
    height: remify(38, 15);
    margin: 0;
    padding: 0;
    position: relative;
    text-indent: -999em;
    width: remify(48, 15);

    .no-flexbox.no-flexboxlegacy.no-flexboxtweener & {
        float: left;
    }

    &:before {
        @include icon("search");

        color: $primary;
        display: block;
        left: 50%;
        position: absolute;
        text-indent: 0;
        transform: translateX(-50%) translateY(-50%);
        top: 50%;

        .no-csstransforms & {
            left: remify(17, 15);
            top: remify(11, 15);
        }
    }
}
