// Styles written by YOURNAME @ YOURCOMPANY

/* ------------------------------------------------------------------------ *\
 * Gridly (adapted from https://github.com/IonicaBizau/gridly)              *
\* ------------------------------------------------------------------------ */

.row {
    & {
        flex-direction: row;
    }
}

.col {
    & {
        flex: 1;
    }

    &.-tenth {
        flex: 0 0 10%;
    }

    &.-fifth {
        flex: 0 0 20%;
    }

    &.-quarter {
        flex: 0 0 25%;
    }

    &.-third {
        flex: 0 0 33.3333334%;
    }

    &.-half {
        flex: 0 0 50%;
    }

    &.-equal {
        flex: 1 1 0%;
    }

    &.-padded:not(:first-child) {
        padding-left: remify(10);
    }

    &.-padded:not(:last-child) {
        padding-right: remify(10);
    }
}
