// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Normalize                                                                *
\* ------------------------------------------------------------------------ */

// import base styles

@import "_vendor";

// custom styles

// Set everything up for box-sizing: border-box!
// http://css-tricks.com/international-box-sizing-awareness-day/

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

// Remove arrows on numbers and date fields in Webkit

::-webkit-outer-spin-button,
::-webkit-inner-spin-button,
::-webkit-calendar-picker-indicator {
    appearance: none;
	display: none;
    margin: 0;
    -webkit-appearance: none;
}

// Remove arrows on numbers and date fields in Mozilla

input[type="number"] {
    -moz-appearance:textfield;
}

// Reset italics on address

address {
    font-style: normal;
}
