// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Content                                                                  *
\* ------------------------------------------------------------------------ */

.contact .logo {
    margin: remify(0 36 0 0, 16);
}

.contact .address {
    padding: remify(0 36, 16);
}

.contact .button {
    margin: remify(0 0 0 36, 20.835);
}

