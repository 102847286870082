// Styles written by Jacob Bearce | jacob@bearce.me

/* ------------------------------------------------------------------------ *\
 * Generic Form Elements                                                    *
\* ------------------------------------------------------------------------ */

input,
label,
.select,
select,
textarea {
    font-size: 20px;
    font-size: remify(20);
}


button,
input[type=button],
input[type=reset],
input[type=submit],
.button {
    font-size: 20px;
    font-size: remify(20);
    padding: remify(10 30, 20.835);
}
